import React from "react";
import FormElementLabel from "./FormElementLabel";
import ErrorMessage from "./ErrorMessage";

const FormInput: React.FC<{
  id: string;
  value: string;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  type?: React.HTMLInputTypeAttribute;
  toolTipText?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}> = ({
  label,
  placeholder,
  id,
  value,
  errorMessage,
  type = "text",
  disabled = false,
  required = false,
  onChange,
}) => {
  return (
    <div className="text-left">
      <div className="flex flex-row [&>*:first-child]:mr-2">
        {!!label && (
          <FormElementLabel label={`${label} ${required ? "*" : ""}`} />
        )}
      </div>
      <input
        placeholder={placeholder}
        type={type}
        name={id}
        id={id}
        value={value}
        className={
          "peer block w-full shadow-sm rounded-md mb-2 py-1.5 border-gray-300 focus:ring-0 focus:border-primary smallTablet:text-md smallTablet:leading-6"
        }
        onChange={onChange}
        disabled={disabled}
        autoComplete="new-password"
        required={required}
      />
      <div className="mb-3">
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </div>
    </div>
  );
};

export default FormInput;
