import React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import FormText from "./FormText";

const FormDropzone: React.FC<{
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  type: "CV" | "Video" | "Other";
}> = ({ setFile, type }) => {
  const { t } = useTranslation("form");

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      accept:
        type === "Video"
          ? {
              "video/mp4": [".mp4"],
              "video/mpeg": [".mpeg"],
              "video/webm": [".webm"],
              "video/mov": [".mov"],
            }
          : {
              "application/pdf": [".pdf"],
              "application/docx": [".docx"],
            },
      onDrop(acceptedFiles) {
        setFile(acceptedFiles[0]);
      },
    });

  const internalFile = acceptedFiles.map((file) => (
    <FormText bold key={file.name} text={file.name} />
  ))[0];

  const getFormText = (type: "CV" | "Video" | "Other") => {
    switch (type) {
      case "Video":
        return t("form:dragAndDropVideo");
      case "Other":
        return t("form:dragAndDropOther");
      default:
        return t("form:dragAndDropCV");
    }
  };
  const text = getFormText(type);
  const backgroundStyling = isDragActive ? "bg-gray-100" : "";
  const borderStyling = internalFile ? "" : " border-dashed";

  return (
    <section
      {...getRootProps()}
      className={`min-h-32 border border-gray-400 ${borderStyling}${backgroundStyling} focus-ring:border-primary hover:opacity-70 hover:cursor-pointer rounded-md  flex justify-center items-center text-center`}
    >
      <div className="m-3">
        <input {...getInputProps()} />
        {internalFile ? (
          <div>
            {internalFile}
            <div className="mt-3">
              <FormText text={t("form:clickToSwitch")} />
            </div>
          </div>
        ) : (
          <FormText
            text={isDragActive ? t("dropHere") : text}
            color={"text-gray-500"}
          />
        )}
      </div>
    </section>
  );
};

export default FormDropzone;
