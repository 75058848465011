import { useTranslation } from "react-i18next";
import H3 from "../Components/forms/H3";

const CookiePolicyView: React.FC = () => {
  const { t } = useTranslation("common");
  return (
    <div className="h-96 w-full flex flex-col justify-center items-center">
      <H3 text={t("cookiePolicy")} />
      <div className="mt-5 mx-10 xl:w-6/12">
        cookie policy cookie policy cookie policy cookie policy cookie policy
        cookie policy cookie policy cookie policy cookie policy cookie policy
        cookie policy cookie policy cookie policy cookie policy cookie policy
        cookie policy cookie policy cookie policy cookie policy cookie policy
        cookie policy cookie policy cookie policy cookie policy cookie policy
        cookie policy cookie policy cookie policy cookie policy
      </div>
    </div>
  );
};

export default CookiePolicyView;
