import React from "react";

const H2: React.FC<{ text: string; color?: string }> = ({
  text,
  color = "text-primary",
}) => {
  return (
    <h2 className={`font-scto text-2xl tracking-tight ${color} sm:text-3xl`}>
      {text}
    </h2>
  );
};

export default H2;
