import React from "react";

const FormText: React.FC<{ text?: string; color?: string; bold?: boolean }> = ({
  text,
  color,
  bold = false,
}) => {
  const textColor = color ? color : "text-primary";
  return (
    <p
      className={`${textColor} ${
        bold ? "font-bold" : ""
      } leading-7 font-helvetica`}
    >
      {text}
    </p>
  );
};

export default FormText;
