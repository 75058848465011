import { useTranslation } from "react-i18next";
import { Company, JobAd } from "../lib/models";
import JobAdCard from "./JobAdCard";
import FormText from "./forms/FormText";

const JobAdsList: React.FC<{ jobAds: JobAd[]; company: Company }> = ({
  jobAds,
  company,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {jobAds.length > 0 ? (
        <ul className="mt-10 mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 md:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {jobAds.map((ad) => (
            <JobAdCard key={ad.jobAdId} jobAd={ad} company={company} />
          ))}
        </ul>
      ) : (
        <div className="w-full mt-10 mb-10">
          <FormText text={t("AdView:noActiveAds")} />
        </div>
      )}
    </>
  );
};

export default JobAdsList;
