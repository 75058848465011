import Spinner from "../Components/Spinner";

const LoadingView: React.FC = () => {
  return (
    <div className="h-96 w-full flex justify-center items-center">
      <Spinner width={50} spinnerColor={"#312938"} />
    </div>
  );
};

export default LoadingView;
