import { useTranslation } from "react-i18next";

import FormText from "../Components/forms/FormText";
import H1 from "../Components/forms/H1";

const NotFoundView: React.FC = () => {
  const { t } = useTranslation(["form", "common"]);

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-primary">404</p>
        <div className="my-4">
          <H1 text={t("404:404-header")} />
        </div>
        <FormText text={t("404:404-text")} />
      </div>
    </main>
  );
};

export default NotFoundView;
