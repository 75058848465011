import React from "react";
import FormText from "./FormText";

const ErrorMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="text-sm">
      <FormText text={message} color="text-warning" />
    </div>
  );
};

export default ErrorMessage;
