import { useTranslation } from "react-i18next";
import { Company } from "../lib/models";
import Button from "./buttons/Button";
import H1 from "./forms/H1";

import { ChevronRightIcon } from "@heroicons/react/24/outline";

const Hero: React.FC<{
  company: Company | null;
  onButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ company, onButtonClick }) => {
  const { t } = useTranslation(["HomeView"]);
  return (
    <div className="relative flex justify-center w-full bg-pitchlerGray">
      <div className="w-full mx-auto max-w-[2600px] md:grid md:grid-cols-12 md:gap-x-8 3xl:px-8">
        {company?.headerUrl && (
          <div className="order-2 md:order-2 md:col-span-7">
            <img
              className="object-cover w-full h-full"
              src={company?.headerUrl}
              alt=""
            />
          </div>
        )}
        <div className="order-1 px-6 pt-10 pb-12 md:order-1 sm:pb-32 md:col-span-5 md:pb-56 md:pt-48">
          <div className="flex flex-col items-center w-full">
            <div>
              <div className="mb-20 md:-mt-40">
                <img className="h-11" alt="" src={company?.logoUrl} />
              </div>
              <div className="flex flex-col justify-between mx-auto md:mx-0 sm:-mb-28">
                <H1 text={company?.shortDescription} />
                <div className="w-48 mt-10">
                  <Button
                    onClick={onButtonClick}
                    text={t("HomeView:jobOpenings")}
                    icon={
                      <ChevronRightIcon
                        width={20}
                        color="primary"
                        cursor={"pointer"}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
