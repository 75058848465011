import React from "react";
import FormText from "./FormText";

const FormElementLabel: React.FC<{ label: string; required?: boolean }> = ({
  label,
  required = false,
}) => {
  return (
    <div className="text-md mb-1 text-gray-700">
      <FormText bold text={`${label} ${required ? "*" : ""}`} />
    </div>
  );
};

export default FormElementLabel;
