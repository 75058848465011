import React from "react";

const H1: React.FC<{ text?: string; color?: string }> = ({
  text,
  color = "text-primary",
}) => {
  return (
    <h1 className={`font-scto text-3xl tracking-tight ${color} md:text-5xl`}>
      {text}
    </h1>
  );
};

export default H1;
