import React from "react";

const Badge: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <span className="font-inter m-2 inline-flex items-center rounded-xl border border-white px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-500/10">
      {text}
    </span>
  );
};

export default Badge;
