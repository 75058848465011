import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useState,
} from "react";
import { Company, JobAd } from "../lib/models";

interface CompanyProps {
  // refetchCompanies: () => void;
  company: Company | null;
  setCompany: Dispatch<SetStateAction<Company | null>>;
  jobAds: JobAd[] | [];
  setJobAds: Dispatch<SetStateAction<JobAd[] | []>>;
  activeJobAd: JobAd | null;
  setActiveJobAd: Dispatch<SetStateAction<JobAd | null>>;
}

export const CompanyContext = createContext<CompanyProps>({} as CompanyProps);

export const CompanyContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [company, setCompany] = useState<Company | null>(null);
  const [jobAds, setJobAds] = useState<JobAd[] | []>([]);
  const [activeJobAd, setActiveJobAd] = useState<JobAd | null>(null);

  return (
    <CompanyContext.Provider
      value={{
        // refetchCompanies,
        company,
        setCompany,
        jobAds,
        setJobAds,
        activeJobAd,
        setActiveJobAd,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
