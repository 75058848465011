import { useTranslation } from "react-i18next";

import H3 from "../Components/forms/H3";

const PrivacyPolicyView: React.FC = () => {
  const { t } = useTranslation("common");
  return (
    <div className="flex flex-col items-center justify-center w-full my-24">
      <H3 text={t("privacyPolicy")} />
      <div className="mx-10 mt-5 xl:w-6/12">
        <p>
          I denna integritetspolicy f&ouml;rklarar vi hur Pitchler behandlar
          dina personuppgifter om du:
        </p>
        <p>
          Anv&auml;nder en tj&auml;nst som Pitchler tillhandah&aring;ller, det
          vill s&auml;ga i) v&aring;ra ATS- och employer branding-tj&auml;nster)
          (b&aring;da kallas &ldquo;Tj&auml;nst&rdquo; och du kallas
          &ldquo;Anv&auml;ndare&rdquo;).
        </p>
        <p>
          Representerar en kund av Pitchler gentemot Pitchler, till exempel
          genom att underteckna avtalet mellan Pitchler och v&aring;r kund
          (&ldquo;Kundens kontaktperson&rdquo;).
        </p>
        <p>
          Representerar ett f&ouml;retag som Pitchler har identifierat som en
          potentiell kund (&ldquo;Potentiell anv&auml;ndare&rdquo;).
        </p>
        <p>
          Representerar ett f&ouml;retag som &auml;r en partner eller
          leverant&ouml;r till Pitchler n&auml;r de tillhandah&aring;ller en
          Tj&auml;nst (&ldquo;Partnerns kontaktperson&rdquo;).
        </p>
        <p>
          Kontaktar Pitchler, till exempel via v&aring;r hemsida eller v&aring;r
          kundsupport, men inte tillh&ouml;r n&aring;gon av de ovan n&auml;mnda
          grupperna (&ldquo;Annan kontaktperson&rdquo;).
        </p>
        <p>
          Denna integritetspolicy beskriver ocks&aring; vilka r&auml;ttigheter
          du har och hur du kan ut&ouml;va dessa r&auml;ttigheter.
        </p>
        <p>
          N&auml;r vi n&auml;mner &ldquo;Pitchler&rdquo;, &ldquo;vi&rdquo; eller
          &ldquo;oss&rdquo; h&auml;nvisar vi till Pitchler Novus AB, Kungsgatan
          26, 111 35 Stockholm, Sverige, registrerat hos Bolagsverket under
          organisationsnummer 559471-4213.
        </p>
        <p>
          <br />
        </p>
        <p>Om behandling av personuppgifter</p>
        <p>
          Personuppgifter &auml;r all information som direkt eller indirekt kan
          kopplas till en levande, fysisk person. Exempel p&aring;
          personuppgifter &auml;r: Namn, e-postadress, telefonnummer och
          IP-adress. Behandling av personuppgifter &auml;r all automatiserad
          anv&auml;ndning av personuppgifter &ndash; till exempel insamling,
          skapande, analys, delning och radering av personuppgifter.
        </p>
        <p>
          Det finns lagar och regler om hur f&ouml;retag f&aring;r behandla
          personuppgifter, s&aring; kallade dataskyddslagar. Olika
          dataskyddslagar g&auml;ller f&ouml;r olika typer av anv&auml;ndning av
          personuppgifter och i olika delar av v&auml;rlden. Ett viktigt exempel
          p&aring; en dataskyddslag som &auml;r relevant f&ouml;r Pitchlers
          anv&auml;ndning av dina personuppgifter, som beskrivs i denna
          integritetspolicy, &auml;r EU
        </p>
        <p>Dataskyddsf&ouml;rordning (2016/679, GDPR).</p>
        <p>
          De flesta skyldigheter enligt dataskyddslagar g&auml;ller f&ouml;r den
          s&aring; kallade personuppgiftsansvarige. En personuppgiftsansvarig
          &auml;r den instans som best&auml;mmer f&ouml;r vilka
          &auml;ndam&aring;l personuppgifter ska behandlas och hur behandlingen
          ska utf&ouml;ras. Den personuppgiftsansvarige kan anv&auml;nda ett
          s&aring; kallat personuppgiftsbitr&auml;de. Ett
          personuppgiftsbitr&auml;de &auml;r en instans som endast f&aring;r
          behandla personuppgifter baserat p&aring; instruktioner fr&aring;n den
          personuppgiftsansvarige och f&aring;r inte anv&auml;nda
          personuppgifterna f&ouml;r egna &auml;ndam&aring;l.
        </p>
        <p>
          <br />
        </p>
        <p>
          &Auml;r Pitchler personuppgiftsansvarig eller
          personuppgiftsbitr&auml;de?
        </p>
        <p>
          <strong>2.1 Anv&auml;ndare</strong>
        </p>
        <p>
          Pitchler behandlar personuppgifter om Anv&auml;ndare b&aring;de som
          personuppgiftsbitr&auml;de och som personuppgiftsansvarig.
        </p>
        <p>
          N&auml;r f&ouml;retaget du representerar registrerar dig f&ouml;r och
          l&aring;ter dig anv&auml;nda en Tj&auml;nst &auml;r f&ouml;retaget
          personuppgiftsansvarig f&ouml;r den behandling av personuppgifter som
          sker inom den Tj&auml;nsten. Pitchler fungerar som dess
          personuppgiftsbitr&auml;de. Kontakta din arbetsgivare f&ouml;r att
          f&aring; information om hur f&ouml;retaget du representerar hanterar
          dina personuppgifter n&auml;r du anv&auml;nder en Tj&auml;nst, eller
          f&ouml;r att ut&ouml;va dina r&auml;ttigheter.
        </p>
        <p>
          Men n&auml;r f&ouml;retaget du representerar registrerar dig f&ouml;r
          och anv&auml;nder en Tj&auml;nst, samlar Pitchler ocks&aring; in en
          begr&auml;nsad m&auml;ngd personuppgifter om dig och anv&auml;nder
          dessa personuppgifter f&ouml;r att tillhandah&aring;lla Tj&auml;nsten,
          f&ouml;r att utveckla och f&ouml;rb&auml;ttra Tj&auml;nsten samt
          f&ouml;r att kommunicera med dig. Pitchler &auml;r
          personuppgiftsansvarig f&ouml;r denna behandling av dina
          personuppgifter. Det &auml;r viktigt att notera att denna behandling
          av dina personuppgifter sker helt frist&aring;ende fr&aring;n hur
          f&ouml;retaget du representerar behandlar dina personuppgifter
          n&auml;r du anv&auml;nder en Tj&auml;nst.
        </p>
        <p>
          <strong>2.2 Kundens kontaktperson</strong>
        </p>
        <p>
          N&auml;r vi ing&aring;r avtal med en kund och n&auml;r vi
          tillhandah&aring;ller en Tj&auml;nst till en kund behandlar vi
          personuppgifter om Kundens kontaktperson som personuppgiftsansvarig.
          Pitchler behandlar dessa personuppgifter f&ouml;r att kunna ing&aring;
          och fullg&ouml;ra avtalet med kunden, och f&ouml;r att kommunicera med
          Kundens kontaktperson.
        </p>
        <p>
          <strong>2.3 Potentiell anv&auml;ndare</strong>
        </p>
        <p>
          N&auml;r vi identifierar ett f&ouml;retag som en potentiell kund och
          n&auml;r vi kontaktar f&ouml;retaget behandlar vi personuppgifter om
          en Potentiell anv&auml;ndare som personuppgiftsansvarig. Pitchler
          behandlar dessa personuppgifter f&ouml;r att kunna marknadsf&ouml;ra
          v&aring;ra produkter och tj&auml;nster samt f&ouml;r att kommunicera
          med den Potentiella anv&auml;ndaren.
        </p>
        <p>
          <strong>2.4 Partnerns kontaktperson</strong>
        </p>
        <p>
          N&auml;r vi ing&aring;r avtal med en partner eller leverant&ouml;r och
          n&auml;r vi tar emot en Tj&auml;nst fr&aring;n en partner eller
          leverant&ouml;r behandlar vi personuppgifter om Partnerns
          kontaktperson som personuppgiftsansvarig. Pitchler behandlar dessa
          personuppgifter f&ouml;r att kunna ing&aring; och fullg&ouml;ra
          avtalet med partnern eller leverant&ouml;ren, och f&ouml;r att
          kommunicera med Partnerns kontaktperson.
        </p>
        <p>
          <strong>2.5 Annan kontaktperson</strong>
        </p>
        <p>
          N&auml;r du kontaktar oss, till exempel via v&aring;r hemsida eller
          v&aring;r kundsupport, men inte tillh&ouml;r n&aring;gon av de ovan
          n&auml;mnda grupperna, behandlar Pitchler personuppgifter om dig som
          personuppgiftsansvarig. Pitchler behandlar dessa personuppgifter
          f&ouml;r att kunna kommunicera med dig och f&ouml;r att hantera ditt
          &auml;rende.
        </p>
        <p>
          <br />
        </p>
        <p>Personuppgifter vi behandlar</p>
        <p>
          <strong>3.1 Anv&auml;ndare</strong>
        </p>
        <p>Vi behandlar f&ouml;ljande personuppgifter om Anv&auml;ndare:</p>
        <p>
          <strong>3.1.1. Kontaktinformation</strong>
        </p>
        <p>Namn</p>
        <p>E-postadress</p>
        <p>Telefonnummer</p>
        <p>F&ouml;retag</p>
        <p>Position</p>
        <p>Bild (frivilligt)</p>
        <p>
          <strong>3.1.2. Loggdata</strong>
        </p>
        <p>IP-adress</p>
        <p>Webbplatsaktivitet</p>
        <p>Webbplatsens URL</p>
        <p>Webbplatsens titel</p>
        <p>Datum och tid f&ouml;r h&auml;ndelsen</p>
        <p>
          Enhetsinformation (till exempel enhetstyp och
          webbl&auml;sarinformation)
        </p>
        <p>
          <strong>3.2 Kundens kontaktperson</strong>
        </p>
        <p>
          Vi behandlar f&ouml;ljande personuppgifter om Kundens kontaktperson:
        </p>
        <p>
          <strong>3.2.1. Kontaktinformation</strong>
        </p>
        <p>Namn</p>
        <p>E-postadress</p>
        <p>Telefonnummer</p>
        <p>F&ouml;retag</p>
        <p>Position</p>
        <p>
          <strong>3.3 Potentiell anv&auml;ndare</strong>
        </p>
        <p>
          Vi behandlar f&ouml;ljande personuppgifter om Potentiell
          anv&auml;ndare:
        </p>
        <p>
          <strong>3.3.1. Kontaktinformation</strong>
        </p>
        <p>Namn</p>
        <p>E-postadress</p>
        <p>Telefonnummer</p>
        <p>F&ouml;retag</p>
        <p>Position</p>
        <p>
          <strong>3.4 Partnerns kontaktperson</strong>
        </p>
        <p>
          Vi behandlar f&ouml;ljande personuppgifter om Partnerns kontaktperson:
        </p>
        <p>
          <strong>3.4.1. Kontaktinformation</strong>
        </p>
        <p>Namn</p>
        <p>E-postadress</p>
        <p>Telefonnummer</p>
        <p>F&ouml;retag</p>
        <p>Position</p>
        <p>
          <strong>3.5 Annan kontaktperson</strong>
        </p>
        <p>
          Vi behandlar f&ouml;ljande personuppgifter om Annan kontaktperson:
        </p>
        <p>
          <strong>3.5.1. Kontaktinformation</strong>
        </p>
        <p>Namn</p>
        <p>E-postadress</p>
        <p>Telefonnummer</p>
        <p>F&ouml;retag</p>
        <p>Position</p>
        <p>
          &Auml;ndam&aring;l och laglig grund f&ouml;r behandling av
          personuppgifter
        </p>
        <p>
          Vi behandlar personuppgifter om Anv&auml;ndare, Kundens kontaktperson,
          Potentiell anv&auml;ndare, Partnerns kontaktperson och Annan
          kontaktperson f&ouml;r f&ouml;ljande &auml;ndam&aring;l och p&aring;
          f&ouml;ljande lagliga grunder:
        </p>
        <p>
          <strong>
            4.1 F&ouml;r att tillhandah&aring;lla Tj&auml;nsten till
            Anv&auml;ndare
          </strong>
        </p>
        <p>
          &Auml;ndam&aring;l: Vi behandlar personuppgifter f&ouml;r att kunna
          tillhandah&aring;lla Tj&auml;nsten till Anv&auml;ndare.
        </p>
        <p>
          Laglig grund: Behandlingen &auml;r n&ouml;dv&auml;ndig f&ouml;r att
          fullg&ouml;ra avtalet mellan Anv&auml;ndaren och f&ouml;retaget som
          Anv&auml;ndaren representerar.
        </p>
        <p>
          <strong>4.2 F&ouml;r att fullg&ouml;ra avtal med kunder</strong>
        </p>
        <p>
          &Auml;ndam&aring;l: Vi behandlar personuppgifter f&ouml;r att kunna
          ing&aring; och fullg&ouml;ra avtal med v&aring;ra kunder.
        </p>
        <p>
          Laglig grund: Behandlingen &auml;r n&ouml;dv&auml;ndig f&ouml;r att
          fullg&ouml;ra avtal med kunder.
        </p>
        <p>
          <strong>
            4.3 F&ouml;r att marknadsf&ouml;ra v&aring;ra produkter och
            tj&auml;nster
          </strong>
        </p>
        <p>
          &Auml;ndam&aring;l: Vi behandlar personuppgifter f&ouml;r att kunna
          marknadsf&ouml;ra v&aring;ra produkter och tj&auml;nster.
        </p>
        <p>
          Laglig grund: Behandlingen &auml;r n&ouml;dv&auml;ndig f&ouml;r
          v&aring;rt ber&auml;ttigade intresse av att marknadsf&ouml;ra
          v&aring;ra produkter och tj&auml;nster till f&ouml;retag som kan vara
          intresserade av dem.
        </p>
        <p>
          <strong>
            4.4 F&ouml;r att ing&aring; och fullg&ouml;ra avtal med partners och
            leverant&ouml;rer
          </strong>
        </p>
        <p>
          &Auml;ndam&aring;l: Vi behandlar personuppgifter f&ouml;r att kunna
          ing&aring; och fullg&ouml;ra avtal med v&aring;ra partners och
          leverant&ouml;rer.
        </p>
        <p>
          Laglig grund: Behandlingen &auml;r n&ouml;dv&auml;ndig f&ouml;r att
          fullg&ouml;ra avtal med partners och leverant&ouml;rer.
        </p>
        <p>
          <strong>
            4.5 F&ouml;r att kommunicera med Anv&auml;ndare, kunder, potentiella
            anv&auml;ndare, partners, leverant&ouml;rer och andra
            kontaktpersoner
          </strong>
        </p>
        <p>
          &Auml;ndam&aring;l: Vi behandlar personuppgifter f&ouml;r att kunna
          kommunicera med Anv&auml;ndare, kunder, potentiella anv&auml;ndare,
          partners, leverant&ouml;rer och andra kontaktpersoner.
        </p>
        <p>
          Laglig grund: Behandlingen &auml;r n&ouml;dv&auml;ndig f&ouml;r att
          tillgodose v&aring;ra ber&auml;ttigade intressen av att kommunicera
          med Anv&auml;ndare, kunder, potentiella anv&auml;ndare, partners,
          leverant&ouml;rer och andra kontaktpersoner.
        </p>
        <p>Delning av personuppgifter</p>
        <p>Vi delar personuppgifter med f&ouml;ljande typer av mottagare:</p>
        <p>
          <strong>5.1 Tj&auml;nsteleverant&ouml;rer</strong>
        </p>
        <p>
          Vi delar personuppgifter med tj&auml;nsteleverant&ouml;rer som
          tillhandah&aring;ller IT-tj&auml;nster och systemplattformar som vi
          anv&auml;nder f&ouml;r att tillhandah&aring;lla Tj&auml;nsten och
          f&ouml;r att hantera v&aring;r verksamhet.
        </p>
        <p>
          <strong>5.2 Aff&auml;rspartners</strong>
        </p>
        <p>
          Vi delar personuppgifter med v&aring;ra aff&auml;rspartners i den
          utstr&auml;ckning det &auml;r n&ouml;dv&auml;ndigt f&ouml;r att
          fullg&ouml;ra v&aring;ra &aring;taganden gentemot dig eller i enlighet
          med ditt samtycke.
        </p>
        <p>
          <strong>5.3 Myndigheter</strong>
        </p>
        <p>
          Vi kan dela personuppgifter med myndigheter om vi &auml;r skyldiga att
          g&ouml;ra det enligt lag eller om det &auml;r n&ouml;dv&auml;ndigt
          f&ouml;r att skydda v&aring;ra r&auml;ttigheter eller intressen.
        </p>
        <p>
          <br />
        </p>
        <p>Dina r&auml;ttigheter</p>
        <p>
          Beroende p&aring; omst&auml;ndigheterna och den lagstiftning som
          &auml;r till&auml;mplig p&aring; behandlingen av dina personuppgifter
          har du vissa r&auml;ttigheter. Dessa r&auml;ttigheter kan omfatta
          r&auml;tten att:
        </p>
        <p>F&aring; tillg&aring;ng till dina personuppgifter.</p>
        <p>
          R&auml;tta felaktiga uppgifter eller komplettera ofullst&auml;ndiga
          uppgifter.
        </p>
        <p>Radera dina personuppgifter.</p>
        <p>Inv&auml;nda mot behandlingen av dina personuppgifter.</p>
        <p>Begr&auml;nsa behandlingen av dina personuppgifter.</p>
        <p>Flytta dina personuppgifter (dataportabilitet).</p>
        <p>
          Kontakta oss om du vill ut&ouml;va n&aring;gon av dina
          r&auml;ttigheter eller om du har n&aring;gra fr&aring;gor om v&aring;r
          behandling av dina personuppgifter. Vi kommer att svara p&aring; din
          f&ouml;rfr&aring;gan och vidta &aring;tg&auml;rder i enlighet med
          g&auml;llande lagstiftning om skydd f&ouml;r personuppgifter.
        </p>
        <p>&Auml;ndringar i denna integritetspolicy</p>
        <p>
          Vi kan komma att uppdatera denna integritetspolicy fr&aring;n tid till
          annan. Vi kommer att meddela dig om vi g&ouml;r v&auml;sentliga
          &auml;ndringar i denna integritetspolicy genom att publicera den
          uppdaterade integritetspolicyn p&aring; v&aring;r webbplats eller via
          e-post.
        </p>
        <p>Kontakta oss</p>
        <p>
          Om du har fr&aring;gor eller funderingar om v&aring;r
          integritetspolicy eller v&aring;r behandling av dina personuppgifter
          kan du kontakta oss p&aring;{" "}
          <a data-fr-linked="true" href="mailto:support@pitchler.com">
            support@pitchler.com
          </a>
          . Du kan ocks&aring; anv&auml;nda v&aring;r kontaktformul&auml;r
          p&aring; v&aring;r webbplats.
        </p>
        <p>Denna integritetspolicy blev senast uppdaterad 2024-06-07.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicyView;
