import { useNavigate } from "react-router-dom";
import { Company, JobAd } from "../lib/models";
import FormText from "./forms/FormText";
import H3 from "./forms/H3";
import { useContext } from "react";
import { CompanyContext } from "../contexts/CompanyContext";
import { EMPTY_FILENAME } from "../lib/utils";

const JobAdCard: React.FC<{ jobAd: JobAd; company: Company }> = ({
  jobAd,
  company,
}) => {
  const navigate = useNavigate();
  const { setActiveJobAd } = useContext(CompanyContext);

  const noBackgroundImage = jobAd.backgroundImageFilename === EMPTY_FILENAME;
  return (
    <div
      onClick={() => {
        setActiveJobAd(jobAd);
        navigate(`/${company.name}/${company?.companyId}/ad/${jobAd.jobAdId}`);
      }}
      key={jobAd.jobAdId}
      className={`max-w-80 border border-gray-200 rounded-md cursor-pointer hover:opacity-95 ${
        noBackgroundImage && "flex items-center justify-center h-56"
      }`}
    >
      {jobAd.backgroundImageUrl && !noBackgroundImage && (
        <img
          className="aspect-[3/2] w-full rounded-t-md object-cover"
          src={jobAd.backgroundImageUrl}
          alt=""
        />
      )}

      <div
        className={`text-center mx-2 flex flex-col ${
          noBackgroundImage ? "items-center mx-4" : "h-28 md:h-30 mt-3"
        }`}
      >
        <span
          className={`${!noBackgroundImage ? "line-clamp-2" : "line-clamp-4"}`}
        >
          <H3 text={jobAd.title} />
        </span>
        <div className="flex-grow"></div>

        <div
          className={`flex flex-row space-x-1 justify-center mt-3 text-xs xl:text-sm ${
            !noBackgroundImage && "mb-5"
          }`}
        >
          {jobAd?.city && <FormText text={jobAd.city} />}
          {jobAd?.employmentType && <FormText text={"•"} />}
          {jobAd?.employmentType && <FormText text={jobAd.employmentType} />}
          {jobAd?.workLocation && <FormText text={"•"} />}
          {jobAd?.workLocation && <FormText text={jobAd.workLocation} />}
        </div>
      </div>
    </div>
  );
};

export default JobAdCard;
