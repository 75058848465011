import React from "react";

import { useNavigate } from "react-router-dom";

const BackButton: React.FC<{ color?: string; path: string }> = ({
  color = "text-white",
  path,
}) => {
  const navigate = useNavigate();
  return (
    <button className={`${color}`} onClick={() => navigate(path)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke={"white"}
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
        />
      </svg>
    </button>
  );
};

export default BackButton;
