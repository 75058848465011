import { Company, CompanyIn, JobAd, JobAdIn } from "./models";

export const mapCompany = (company: CompanyIn): Company => {
  const newCompany: Company = {
    companyId: company.company_id,
    name: company.name,
    description: company.description,
    shortDescription: company.short_description,
    organisationNumber: company.organisation_number,
    headerUrl: company.header_image_url,
    logoUrl: company.logo_url,
  };
  return newCompany;
};

export const mapJobAds = (jobAds: JobAdIn[]): JobAd[] => {
  return jobAds.map((jobAd) => mapJobAd(jobAd));
};

export const mapJobAd = (jobAd: JobAdIn): JobAd => {
  const newJobAd: JobAd = {
    jobAdId: jobAd.job_ad_id,
    title: jobAd.title,
    description: jobAd.description,
    companyId: jobAd.company_id,
    createdDate: jobAd.created_date,
    lastApplicationDate: jobAd.last_application_date,
    updatedAt: jobAd.updated_at,
    city: jobAd.city,
    swedishRegionCode: jobAd.swedish_region_code,
    worktimeExtent: jobAd.worktime_extent,
    published: jobAd.published,
    archived: jobAd.archived,
    employmentType: jobAd.employment_type?.replace(/\s*\(.*?\)\s*/g, "").trim(),
    weblink: jobAd.weblink,
    workLocation: jobAd.work_location,
    requirements: jobAd.requirements,
    backgroundImageUrl: jobAd.background_image_url,
    backgroundImageFilename: jobAd.background_image_filename,
  };
  return newJobAd;
};
