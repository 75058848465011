import { useTranslation } from "react-i18next";
import { Company, JobAd } from "../lib/models";
import Badge from "./Badge";
import BackButton from "./buttons/BackButton";
import Button from "./buttons/Button";
import H1 from "./forms/H1";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { EMPTY_FILENAME } from "../lib/utils";

const JobAdHeader: React.FC<{
  jobAd?: JobAd | null;
  company?: Company | null;
  showButton?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ jobAd, company, showButton = false, onClick }) => {
  const { t } = useTranslation(["ApplicationView"]);
  const navigate = useNavigate();
  const { jobAdId } = useParams();
  return (
    <div className="relative px-6 py-24 overflow-hidden bg-gray-900 isolate sm:py-32 lg:px-8">
      <div className="flex flex-row justify-between -mt-20">
        <BackButton
          path={
            showButton
              ? `/${company?.name}/${company?.companyId}`
              : `/ad/${jobAdId}`
          }
        />
        <img
          onClick={() => navigate(`/${company?.name}/${company?.companyId}`)}
          src={company?.logoUrl}
          alt=""
          className="w-32 mb-5 md:w-40 md:mb-0 hover:cursor-pointer"
        />
      </div>

      {jobAd?.backgroundImageUrl &&
        jobAd.backgroundImageFilename !== EMPTY_FILENAME && (
          <img
            src={jobAd?.backgroundImageUrl}
            alt=""
            className="absolute inset-0 object-cover w-full h-full -z-10 opacity-35"
          />
        )}
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>

      <div className="flex flex-col items-center justify-center max-w-4xl mx-auto text-center">
        <div className="mb-5">
          {jobAd?.city && <Badge text={jobAd.city} />}
          {jobAd?.employmentType && <Badge text={jobAd.employmentType} />}
          {jobAd?.workLocation && <Badge text={jobAd.workLocation} />}
        </div>
        <H1 text={jobAd?.title} color="text-white" />
        <div className="h-10 mt-5 -mb-16 min-w-32 max-w-40">
          {showButton && onClick && (
            <Button
              variant="contained-inverted"
              text={t("ApplicationView:apply")}
              onClick={onClick}
              icon={<ChevronRightIcon width={20} color="primary" />}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default JobAdHeader;
