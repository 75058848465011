import { useContext, useEffect, useRef } from "react";
import { CompanyContext } from "../contexts/CompanyContext";
import Hero from "../Components/Hero";
import JobAdsList from "../Components/JobAdsList";
import FormText from "../Components/forms/FormText";
import H2 from "../Components/forms/H2";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getCompany, getCompanyJobAds } from "../lib/api";
import LoadingView from "./LoadingView";

const HomeView: React.FC = () => {
  const { setCompany, company, setJobAds, jobAds } = useContext(CompanyContext);
  const { t } = useTranslation(["HomeView", "common"]);
  const { companyId } = useParams();
  const location = useLocation();
  const jobAdsRef = useRef<HTMLDivElement | null>(null);

  const useCompanyData = (companyId: string | undefined) => {
    return useQuery({
      queryKey: ["company", companyId],
      queryFn: async () => {
        if (companyId) {
          const company = await getCompany(companyId);
          return company;
        }
        return null;
      },
      enabled: !!companyId,
    });
  };

  const useJobAdsData = (companyId: string | undefined) => {
    return useQuery({
      queryKey: ["jobAds", companyId],
      queryFn: async () => {
        if (companyId) {
          const jobAds = await getCompanyJobAds(companyId);
          return jobAds;
        }
        return null;
      },
      enabled: !!companyId,
    });
  };

  useEffect(() => {
    if (location.state?.targetId) {
      handleClick();
    }
  }, [location]);

  const {
    data: companyData,
    error: companyError,
    isLoading: companyLoading,
  } = useCompanyData(companyId);
  const { data: jobAdsData } = useJobAdsData(companyId);

  useEffect(() => {
    if (companyData) {
      setCompany(companyData);
    }
    if (jobAdsData) {
      setJobAds(jobAdsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData, jobAdsData]);

  const handleClick = () => {
    jobAdsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="">
      {companyLoading || companyError ? (
        <>
          {companyError ? (
            <div className="mt-20 text-center">
              <FormText
                text={`${t("common:somethingWentWrong")}`}
                color="text-warning"
              />
            </div>
          ) : (
            <LoadingView />
          )}
        </>
      ) : (
        <>
          <Hero onButtonClick={handleClick} company={company} />
          <div className="flex flex-col items-center justify-center mx-10 mt-5 sm:mx-20 sm:mt-10">
            <div className="max-w-5xl my-5 [&>*:first-child]:mb-5 [&>*:first-child]:text-center">
              <H2 text={`${t("moreOfCompany")} ${company?.name}`} />
              <FormText text={company?.description} />
            </div>
            <div ref={jobAdsRef} className="mt-10">
              <div className="text-center">
                <H2 text={t("jobOpenings")} />
              </div>
              {jobAds && company && (
                <div id="jobAdsList">
                  <JobAdsList jobAds={jobAds} company={company} />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HomeView;
