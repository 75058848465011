import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/Routes";
import { I18nextProvider } from "react-i18next";
import { i18next } from "./lib/i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CompanyContextProvider } from "./contexts/CompanyContext";
import ScrollToTop from "./Components/ScrollToTop";
import Footer from "./Components/Footer";
import "./assets/fonts/fonts.css";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18next}>
        <CompanyContextProvider>
          <BrowserRouter>
            <Routes />
            <ScrollToTop />
            <Footer />
          </BrowserRouter>
        </CompanyContextProvider>
      </I18nextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
