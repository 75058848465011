import React from "react";
import parse, { domToReact } from "html-react-parser";

const FormHtmlText: React.FC<{
  text: string;
  color?: string;
  bold?: boolean;
}> = ({ text, color, bold = false }) => {
  const textColor = color ? color : "text-primary";

  // We need this since tailwind preflight is removing certain styling from the html
  const options = {
    replace: (domNode: any) => {
      if (domNode.attribs && domNode.name === "span") {
        return (
          <span className={domNode.attribs.class}>
            {domToReact(domNode.children, options)}
          </span>
        );
      }
      if (domNode.attribs && domNode.name === "ul") {
        return (
          <ul className="ml-5 list-disc list-inside">
            {domToReact(domNode.children, options)}
          </ul>
        );
      }
      if (domNode.attribs && domNode.name === "ol") {
        return (
          <ol className="ml-5 list-decimal list-inside">
            {domToReact(domNode.children, options)}
          </ol>
        );
      }
      if (domNode.attribs && domNode.name === "h3") {
        return (
          <h3 className="text-lg font-semibold">
            {domToReact(domNode.children, options)}
          </h3>
        );
      }
      if (domNode.attribs && domNode.name === "h2") {
        return (
          <h3 className="text-xl font-bold">
            {domToReact(domNode.children, options)}
          </h3>
        );
      }
      if (domNode.attribs && domNode.name === "h1") {
        return (
          <h3 className="text-2xl font-bold">
            {domToReact(domNode.children, options)}
          </h3>
        );
      }
      if (domNode.attribs && domNode.name === "h4") {
        return (
          <h3 className="font-semibold">
            {domToReact(domNode.children, options)}
          </h3>
        );
      }
    },
  };

  return (
    <span
      className={`${textColor} ${
        bold ? "font-bold" : ""
      } leading-7 font-helvetica`}
    >
      {parse(text, options)}
    </span>
  );
};

export default FormHtmlText;
