import React from "react";

const H3: React.FC<{ text: string; color?: string }> = ({
  text,
  color = "text-primary",
}) => {
  return (
    <h2 className={`font-scto text-md tracking-tight ${color} sm:text-lg`}>
      {text}
    </h2>
  );
};

export default H3;
