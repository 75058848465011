import { Route, Routes as Router } from "react-router-dom";
import HomeView from "../Views/HomeView";
import AdView from "../Views/AdView";
import NotFoundView from "../Views/NotFoundView";
import PrivacyPolicyView from "../Views/PrivacyPolicyView";
import CookiePolicyView from "../Views/CookiePolicyView";

const Routes = () => {
  return (
    <Router>
      <Route path="*" element={<NotFoundView />} />
      <Route path="/:companyName/:companyId" element={<HomeView />} />
      <Route path="/:companyName/:companyId/ad/:jobAdId" element={<AdView />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyView />} />
      <Route path="/cookie-policy" element={<CookiePolicyView />} />
    </Router>
  );
};

export default Routes;
