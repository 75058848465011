import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";

import ApplicationForm from "../Components/ApplicationForm";
import Button from "../Components/buttons/Button";
import FormHtmlText from "../Components/forms/FormHtmlText";
import FormText from "../Components/forms/FormText";
import H2 from "../Components/forms/H2";
import JobAdHeader from "../Components/JobAdHeader";
import JobAdsList from "../Components/JobAdsList";
import { CompanyContext } from "../contexts/CompanyContext";
import { getCompany, getCompanyJobAds } from "../lib/api";
import { JobAd } from "../lib/models";
import LoadingView from "./LoadingView";

const AdView: React.FC = () => {
  const navigate = useNavigate();
  const applicationFormRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation(["AdView"]);
  const { jobAdId, companyId } = useParams();
  const {
    jobAds,
    company,
    activeJobAd,
    setActiveJobAd,
    setJobAds,
    setCompany,
  } = useContext(CompanyContext);
  const [applicationMode, setApplicationMode] = useState<boolean>(false);

  const getThreeRandomAds = (ads: JobAd[]): JobAd[] => {
    const arrayCopy = ads.filter((ad) => ad.jobAdId !== jobAdId).slice();
    const numElements = 3;
    for (let i = arrayCopy.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
    }
    return arrayCopy.slice(0, numElements);
  };

  const useCompanyData = (companyId: string | undefined) => {
    return useQuery({
      queryKey: ["company", companyId],
      queryFn: async () => {
        if (companyId) {
          const company = await getCompany(companyId);
          return company;
        }
        return null;
      },
      enabled: !!companyId && !company,
    });
  };

  const useJobAdsData = (companyId: string | undefined) => {
    return useQuery({
      queryKey: ["jobAds", companyId],
      queryFn: async () => {
        if (companyId) {
          const jobAds = await getCompanyJobAds(companyId);
          return jobAds;
        }
        return null;
      },
      enabled: !activeJobAd && !!companyId && !!jobAdId,
    });
  };

  const {
    data: companyData,
    error: companyError,
    isLoading: companyLoading,
  } = useCompanyData(companyId);
  const {
    data: jobAdsData,
    error: jobAdsError,
    isLoading: jobAdsLoading,
  } = useJobAdsData(companyId);

  useEffect(() => {
    if (companyData) {
      setCompany(companyData);
    }
    if (jobAdsData) {
      const foundJobAd = jobAdsData.find((ad) => ad.jobAdId === jobAdId);
      if (foundJobAd) {
        setActiveJobAd(foundJobAd);
      }
      setJobAds(jobAdsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData, jobAdsData]);

  const handleClick = () => {
    setApplicationMode(true);
    applicationFormRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div>
      {companyLoading || jobAdsLoading || companyError || jobAdsError ? (
        <>
          {companyError || jobAdsError ? (
            <div className="mt-20 text-center">
              <FormText
                text={t("common:somethingWentWrong")}
                color="text-warning"
              />
            </div>
          ) : (
            <LoadingView />
          )}
        </>
      ) : (
        <>
          {activeJobAd ? (
            <>
              <JobAdHeader
                showButton
                jobAd={activeJobAd}
                company={company}
                onClick={handleClick}
              />
              <div className="max-w-xl m-5 mt-10 sm:mx-auto">
                <div className="mb-2">
                  <FormText
                    color="text-primaryHover"
                    bold
                    text={`${t("AdView:lastDate")}: ${
                      activeJobAd.lastApplicationDate
                        ? new Date(
                            activeJobAd.lastApplicationDate
                          ).toLocaleDateString("sv-SE", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                          })
                        : ""
                    }`}
                  />
                </div>
                <FormHtmlText
                  text={(activeJobAd?.description || "") as unknown as string}
                />
                {!applicationMode && (
                  <div className="mt-10">
                    <Button
                      text={t("ApplicationView:apply")}
                      onClick={handleClick}
                    />
                  </div>
                )}
              </div>
              <div ref={applicationFormRef}>
                {applicationMode && jobAdId && (
                  <ApplicationForm jobAdId={jobAdId} />
                )}
              </div>
            </>
          ) : (
            <>
              <JobAdHeader
                showButton={true}
                jobAd={activeJobAd}
                company={company}
                onClick={handleClick}
              />
              <div className="flex justify-center w-full mt-14">
                <p className="text-xl font-semibold font-helvetica">
                  {t("AdView:notAvailable")}
                </p>
              </div>
            </>
          )}
          <div className="flex flex-col items-center justify-center mt-20">
            <H2 text={t("AdView:moreJobs")} />
            <div className="mx-10 mb-10 sm:mx-32">
              {jobAds && company && (
                <JobAdsList
                  jobAds={getThreeRandomAds(jobAds)}
                  company={company}
                />
              )}
            </div>
            <Button
              variant="text"
              icon={<ChevronRightIcon width={20} color="primary" />}
              text={t("AdView:allJobs")}
              onClick={() =>
                navigate(`/${company?.name}/${company?.companyId}`, {
                  state: { targetId: "jobAdsList" },
                })
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AdView;
