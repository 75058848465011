import React, { Fragment, ReactElement } from "react";

import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const Dialog: React.FC<{
  show: boolean;
  onClose: () => void;
  content: ReactElement;
  title?: string;
}> = ({ show, onClose, content, title }) => {
  return (
    <div>
      <Transition.Root show={show} as={Fragment}>
        <HeadlessDialog as="div" className="relative z-50" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500  z-1000 bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex h-full justify-center items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 smallTablet:translate-y-0 smallTablet:scale-95"
                enterTo="opacity-100 translate-y-0 smallTablet:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 smallTablet:scale-100"
                leaveTo="opacity-0 translate-y-4 smallTablet:translate-y-0 smallTablet:scale-95"
              >
                <HeadlessDialog.Panel className="max-h-19/20 overflow-x-auto overflow-y-auto relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all smallTablet:my-8 desktop:w-6/12 w-10/12 max-w-screen smallTablet:p-6">
                  <div className="flex justify-end items-center w-full">
                    <XMarkIcon
                      width={30}
                      onClick={onClose}
                      color="#9CA3AF"
                      cursor={"pointer"}
                    />
                  </div>
                  {content}
                </HeadlessDialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </HeadlessDialog>
      </Transition.Root>
    </div>
  );
};

export default Dialog;
