import React from "react";
import { useTranslation } from "react-i18next";

import { LanguageSelector } from "./LanguageSelector";
import PitchlerLogo from "./PitchlerLogo";
import FormTextSmall from "./forms/FormTextSmall";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const { t } = useTranslation(["Footer", "common"]);
  const navigate = useNavigate();

  return (
    <footer className="w-full bg-white " aria-labelledby="footer-heading">
      <div className="pt-1 pb-8">
        <div className="flex flex-col justify-between px-8 pt-8 border-gray-900/10 sm:mt-20">
          <div className="mb-4">
            <PitchlerLogo />
          </div>
          <div className="flex flex-row justify-between">
            <FormTextSmall text={t("Footer:toolFromPitchler")} />
            <LanguageSelector />
          </div>
        </div>
        <div className="pt-8 mt-2 border-t border-gray-900/10 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2"></div>
          <div className="flex flex-col justify-between w-full px-8 md:flex-row">
            <FormTextSmall text={t("copyright")} />
            <div className="flex flex-col mt-2 sm:mt-0 md:flex-row md:space-x-4">
              <div
                onClick={() =>
                  window.open(process.env.REACT_APP_PITCHLER_ATS_URL, "_blank")
                }
                className="hover:cursor-pointer"
              >
                <FormTextSmall bold text={t("Footer:loginRecruiters")} />
              </div>
              <div
                onClick={() => navigate("/privacy-policy")}
                className="hover:cursor-pointer"
              >
                <FormTextSmall bold text={t("common:privacyPolicy")} />
              </div>
              {/* No cookies policy needed yet, uncomment when needed */}
              {/* <div
                onClick={() => navigate("/cookie-policy")}
                className="hover:cursor-pointer"
              >
                <FormTextSmall bold text={t("common:cookiePolicy")} />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
