import React, { ReactElement } from "react";

import Spinner from "../Spinner";

const Button: React.FC<{
  text: string;
  type?: "button" | "submit" | "reset" | undefined;
  variant?:
    | "contained"
    | "contained-inverted"
    | "outlined"
    | "outlined-white"
    | "text";
  fullWidth?: boolean;
  isLoading?: boolean;
  icon?: ReactElement;
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({
  text,
  type = "button",
  variant = "contained",
  isLoading = false,
  icon = undefined,
  disabled = false,
  onClick,
}) => {
  const getStyling = (
    variant:
      | "contained"
      | "contained-inverted"
      | "outlined"
      | "outlined-white"
      | "text"
  ) => {
    switch (variant) {
      case "outlined":
        return `text-primary bg-opacity-0 bg-white border border-primary ${
          disabled ? "" : "hover:text-primaryHover hover:border-primaryHover"
        }`;
      case "outlined-white":
        return `text-white bg-opacity-0 ${
          disabled ? "" : "hover:bg-opacity-10"
        }  bg-white border border-white`;
      case "text":
        return `text-primary ${disabled ? "" : "hover:text-primaryHover"}`;
      case "contained-inverted":
        return `text-primary bg-white ${
          disabled ? "" : "hover:bg-gray-200 hover:text-primaryHover"
        }`;
      default:
        return `text-white bg-primary ${
          disabled ? "" : "hover:bg-primaryHover"
        }`;
    }
  };
  let variantStyling = getStyling(variant);

  const disabledStyling = disabled ? "opacity-70" : "";

  return (
    <button
      type={type}
      className={`${variantStyling} block w-full rounded-3xl px-3.5 py-2.5 text-center text-sm font-bold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary  ${disabledStyling}`}
      onClick={onClick}
      disabled={isLoading || disabled}
    >
      {isLoading ? (
        <Spinner width={20} />
      ) : (
        <div className="flex flex-row justify-center [&>*:first-child]:mr-2">
          <div className="font-helvetica">{text}</div>
          {icon}
        </div>
      )}
    </button>
  );
};

export default Button;
