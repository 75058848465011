import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ENFlag = () => (
  <svg viewBox="0 0 24 24">
    <path
      d="M23.858 10.153H13.825V.143a11.875 11.875 0 011.228.254v8.358l6.115-4.49q.254.3.487.616l-5.561 4.052h2.045l4.184-3.045a11.9 11.9 0 01.843 1.72L21.54 8.933h2.061a11.813 11.813 0 01.257 1.22zM23.862 13.834H13.83v10.039a11.436 11.436 0 001.227-.255v-8.374l6.121 4.5a11.99 11.99 0 001.146-1.612l-4.205-3.065h2.044l2.686 2.078q.174-.366.323-.745l-1.63-1.33h2.065a11.971 11.971 0 00.255-1.236zM12.634 23.996a11.592 11.592 0 001.2-.123zm-1.268 0zM.138 13.834a11.971 11.971 0 00.255 1.233h2.045L.823 16.385a11.791 11.791 0 00.841 1.725l4.174-3.043h2.06L2.33 19.121q.23.314.481.612l6.109-4.488v8.364a11.56 11.56 0 001.227.256V13.834zM8.928.397V8.75l-6.1-4.481a11.929 11.929 0 00-1.15 1.617l4.183 3.046H3.813L1.15 6.873q-.173.364-.32.741l1.611 1.314H.4a11.821 11.821 0 00-.256 1.224h10.013V.142a12.05 12.05 0 00-1.229.255z"
      fill="#fff"
    />
    <path
      d="M24 12.007a12.164 12.164 0 01-.138 1.827H13.83v10.039l-1.2.123h-1.26l-1.216-.126V13.834H.138a12.14 12.14 0 010-3.682H10.15V.142a12.154 12.154 0 013.68 0v10.014h10.027A12.067 12.067 0 0124 12.007z"
      fill="#cf142b"
    />
    <path
      d="M21.168 4.258l-6.115 4.49V.39q.48.126.943.29a12.025 12.025 0 015.172 3.578zM21.178 19.741a12.022 12.022 0 01-5.182 3.588q-.463.163-.943.289v-8.374zM8.928 15.244v8.365q-.469-.124-.923-.284a12.025 12.025 0 01-5.189-3.6zM8.928.398v8.353l-6.1-4.481A12.018 12.018 0 018.008.683q.451-.16.92-.285zM2.44 8.929H.399q.125-.473.287-.93c.046-.129.093-.257.144-.384zM23.601 8.928H21.54l1.626-1.325q.078.2.148.4.166.452.287.925zM23.606 15.067q-.124.474-.285.931-.07.2-.149.4l-1.631-1.33zM2.44 15.067L.824 16.385q-.076-.192-.144-.387-.161-.457-.285-.931z"
      fill="#00247d"
    />
    <path
      d="M22.323 5.883l-4.184 3.045h-2.044l5.561-4.052a12.037 12.037 0 01.667 1.007zM7.905 15.067l-5.571 4.054a11.806 11.806 0 01-.669-1.011l4.176-3.043zM5.86 8.933H3.815L1.152 6.874q.239-.506.525-.987zM22.849 17.143q-.24.506-.525.986l-4.208-3.06h2.045z"
      fill="#cf142b"
    />
  </svg>
);

const SVFlag = () => (
  <svg viewBox="0 0 24 24">
    <g fill="#005293">
      <path d="M23.758 9.588H10.304V.117A12.047 12.047 0 0111.726 0h.557a11.957 11.957 0 013.72.679 12.026 12.026 0 017.311 7.308 11.937 11.937 0 01.444 1.601z" />
      <path d="M23.761 14.412a11.977 11.977 0 01-7.759 8.922 11.943 11.943 0 01-3.347.665h-1.3q-.531-.028-1.049-.1v-9.487z" />
      <path d="M5.501 14.412v7.7a11.963 11.963 0 01-5.263-7.7z" />
      <path d="M5.501 1.905v7.683H.242a11.932 11.932 0 01.443-1.6 12.03 12.03 0 014.816-6.083z" />
      <path
        d="M24 12v.008a12.087 12.087 0 01-.238 2.392H10.3v9.5a11.9 11.9 0 01-2.3-.564 11.935 11.935 0 01-2.5-1.227v-7.707H.235a12.086 12.086 0 01-.238-2.392v-.008a12.027 12.027 0 01.242-2.4H5.5V1.909A11.936 11.936 0 018 .682a11.928 11.928 0 012.3-.564v9.483h13.459A12.027 12.027 0 0124 12z"
        fill="#fecb00"
      />
    </g>
  </svg>
);

export const LanguageSelector = () => {
  const { i18n } = useTranslation("LanguageSelector");
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const LANGUAGE = "language";

  useEffect(() => {
    if (!selectedLanguage) {
      const localeLanguage = getLanguage();
      if (localeLanguage) {
        setSelectedLanguage(localeLanguage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLanguage = () => {
    return localStorage.getItem(LANGUAGE);
  };

  const setLanguage = (language: string) => {
    if (language) {
      localStorage.setItem(LANGUAGE, language);
      setSelectedLanguage(language);
    }
  };

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setLanguage(language);
  };

  const svStyling =
    selectedLanguage === "en" ? "opacity-50 hover:opacity-100" : "";
  const enStyling =
    selectedLanguage === "sv" ? "opacity-50 hover:opacity-100" : "";

  return (
    <>
      <div className="w-16 h-8 flex flex-row [&>*:first-child]:mr-2">
        <div
          onClick={() => changeLanguage("sv")}
          className={`hover:cursor-pointer w-8/12 h-8/12 ${svStyling}`}
        >
          <SVFlag />
        </div>
        <div
          onClick={() => changeLanguage("en")}
          className={`hover:cursor-pointer w-8/12 h-8/12 ${enStyling}`}
        >
          <ENFlag />
        </div>
      </div>
    </>
  );
};
