import { mapCompany, mapJobAds } from "./mappings";
import { Company, CompanyIn, JobAd, JobAdIn } from "./models";

async function makeApiCall(
  endpoint: string,
  apiMethod: "POST" | "GET" | "PUT" | "DELETE" | "HEAD",
  body?: any
): Promise<Response> {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}${endpoint}`;
  // const headers = new Headers({
  //   "Content-Type": "application/json",
  // });

  return await fetch(url, {
    method: apiMethod,
    // headers,
    body,
  });
}

export const getCompany = async (companyId: string): Promise<Company> => {
  const response = await makeApiCall(`/companies/${companyId}/public`, "GET");
  const responseJson = await response.json();
  const company = mapCompany(responseJson as CompanyIn);
  return company;
};

export const getCompanyJobAds = async (companyId: string): Promise<JobAd[]> => {
  const response = await makeApiCall(`/job-ads/${companyId}/public`, "GET");
  const responseJson = await response.json();
  const jobAds = mapJobAds(responseJson as JobAdIn[]);
  return jobAds;
};

export const postCandidateApplication = async (
  body: FormData,
  jobAdId: string
): Promise<Response> => {
  const response = await makeApiCall(
    `/web-applications/job-ads/${jobAdId}/application`,
    "POST",
    body
  );
  return response;
};
