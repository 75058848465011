import React from "react";

const FormTextSmall: React.FC<{
  text?: string;
  color?: string;
  bold?: boolean;
}> = ({ text, color, bold = false }) => {
  const textColor = color ? color : "text-primary";
  return (
    <p
      className={`font-inter ${textColor} ${
        bold ? "font-bold" : ""
      } text-xs text-gray-500`}
    >
      {text}
    </p>
  );
};

export default FormTextSmall;
